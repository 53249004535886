"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "image-tp" };
const vue_3 = require("vue");
const element_plus_1 = require("element-plus");
// @ts-ignore
const url_js_1 = require("../../../../utils/url.js");
exports.default = (0, vue_1.defineComponent)({
    props: ["data"],
    emits: ["endStudy"],
    setup(__props, { expose, emit }) {
        const props = __props;
        const datas = (0, vue_3.reactive)({
            showImageViewer: false,
            imgList: [],
        });
        /* if(props.data.handout_content.length==1){
            emit("endStudy");
        } */
        function m(e) {
            e.preventDefault();
        }
        ;
        function fileCl() {
            datas.imgList.splice(0);
            [props.data].forEach((item) => {
                datas.imgList.push((0, url_js_1.correctURL)('file', item.file_url));
            });
            document.body.style.overflow = "hidden";
            document.addEventListener("touchmove", m, false); // 禁止页面滑动
            datas.showImageViewer = true;
        }
        function closeImgViewer() {
            document.body.style.overflow = "auto";
            document.removeEventListener("touchmove", m, true);
            datas.showImageViewer = false;
        }
        function handleChange(newVal) {
            /* if (props.data.handout_content.length - 1 == newVal) {
              emit("endStudy");
            } */
        }
        expose({
            fileCl,
        });
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createVNode)((0, vue_2.unref)(element_plus_1.ElCarousel), {
                    height: "600px",
                    autoplay: false,
                    loop: false,
                    onChange: handleChange
                }, {
                    default: (0, vue_2.withCtx)(() => [
                        ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)([__props.data], (item, index) => {
                            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(element_plus_1.ElCarouselItem), { key: index }, {
                                default: (0, vue_2.withCtx)(() => [
                                    (0, vue_2.createVNode)((0, vue_2.unref)(element_plus_1.ElImage), {
                                        style: { "width": "100%", "height": "600px", "pointer-events": "none" },
                                        src: (0, vue_2.unref)(url_js_1.correctURL)('file', item.file_url),
                                        fit: "scale-down"
                                    }, null, 8, ["src"])
                                ]),
                                _: 2
                            }, 1024));
                        }), 128))
                    ]),
                    _: 1
                }),
                ((0, vue_2.unref)(datas).showImageViewer)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(element_plus_1.ElImageViewer), {
                        key: 0,
                        onClose: closeImgViewer,
                        "url-list": (0, vue_2.unref)(datas).imgList
                    }, null, 8, ["url-list"]))
                    : (0, vue_2.createCommentVNode)("", true)
            ]));
        };
    }
});
